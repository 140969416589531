$primaryColor: #2c7a34 !default;
$primaryDarkColor: #2c7a34 !default;
$primaryDarkerColor: #1a471e !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

$secondaryColor: #64748b !default;
$secondaryLightColor: #696A8B !default;
$secondaryDarkColor: #465761 !default;
$secondaryDarkerColor: #465761 !default;
$secondaryTextColor: #ffffff !default;

@import '../_variables';
@import './_fonts';
@import 'assets/theme-base/_components';
@import '../_extensions';
