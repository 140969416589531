//
// Core: Layout
//

html.scroll-smooth {
    scroll-behavior: smooth;
}

html,
body,
.wrapper {
    min-height: 100vh !important;
    height: 100% !important;
}


#container {
    min-height: 100vh !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
}

.wrapper {
    position: relative;

    .content-wrapper {
        min-height: calc(100vh - #{$main-header-height} - #{$main-footer-height});
    }

    .layout-boxed & {
        @include box-shadow(0 0 10 rgba($black, .3));

        &,
        &::before {
            margin: 0 auto;
            max-width: $boxed-layout-max-width;
            overflow: hidden;
        }

        .main-sidebar {
            left: inherit;
        }
    }

    @supports not (-webkit-touch-callout: none) {
        .layout-fixed & .sidebar {
            height: calc(100vh - (#{$main-header-height-inner} + #{$main-header-bottom-border-width}));
        }
        .layout-fixed.text-sm & .sidebar {
            height: calc(100vh - (#{$main-header-height-sm-inner} + #{$main-header-bottom-border-width}));
        }
    }

    .layout-navbar-fixed.layout-fixed & {
        .control-sidebar {
            top: $main-header-height;
        }

        .main-header.text-sm ~ .control-sidebar {
            top: $main-header-height-sm;
        }

        .sidebar {
            margin-top: $main-header-height;
        }

        .brand-link.text-sm ~ .sidebar {
            margin-top: $main-header-height-sm;
        }
    }

    .layout-navbar-fixed.layout-fixed.text-sm & {
        .control-sidebar {
            top: $main-header-height-sm;
        }

        .sidebar {
            margin-top: $main-header-height-sm;
        }
    }

    .layout-navbar-fixed.sidebar-mini.sidebar-collapse &,
    .layout-navbar-fixed.sidebar-mini-md.sidebar-collapse &,
    .layout-navbar-fixed.sidebar-mini-xs.sidebar-collapse & {
        .brand-link {
            height: $main-header-height;
            width: $sidebar-mini-width;

            &.text-sm {
                height: $main-header-height-sm;
            }
        }
    }

    .layout-navbar-fixed.sidebar-mini.sidebar-collapse.text-sm &,
    .layout-navbar-fixed.sidebar-mini-md.sidebar-collapse.text-sm &,
    .layout-navbar-fixed.sidebar-mini-xs.sidebar-collapse.text-sm & {
        .brand-link {
            height: $main-header-height-sm;
        }
    }

    body:not(.layout-fixed).layout-navbar-fixed & {
        .main-sidebar {
            // margin-top: calc(#{$main-header-height} / -1);

            // .sidebar {
            //   margin-top: $main-header-height;
            // }
        }
    }

    body:not(.layout-fixed).layout-navbar-fixed.text-sm & {
        .main-sidebar {
            margin-top: calc(#{$main-header-height-sm} / -1);

            .sidebar {
                margin-top: $main-header-height-sm;
            }
        }
    }

    .layout-navbar-fixed & {
        .control-sidebar {
            top: 0;
        }

        a.anchor {
            display: block;
            position: relative;
            top: calc((#{$main-header-height-inner} + #{$main-header-bottom-border-width} + (#{$main-header-link-padding-y} * 2)) / -1);
        }

        .main-sidebar:hover {
            .brand-link {
                transition: width $transition-speed $transition-fn;
                width: $sidebar-width;
            }
        }

        .brand-link {
            overflow: hidden;
            position: fixed;
            top: 0;
            transition: width $transition-speed $transition-fn;
            width: $sidebar-width;
            z-index: $zindex-main-header + 1;
        }

        // Sidebar variants brand-link fix
        @each $name, $color in $theme-colors {
            .sidebar-dark-#{$name} .brand-link:not([class*="navbar"]) {
                background-color: $sidebar-dark-bg;
            }

            .sidebar-light-#{$name} .brand-link:not([class*="navbar"]) {
                background-color: $sidebar-light-bg;
            }
        }

        @each $name, $color in $colors {
            .sidebar-dark-#{$name} .brand-link:not([class*="navbar"]) {
                background-color: $sidebar-dark-bg;
            }

            .sidebar-light-#{$name} .brand-link:not([class*="navbar"]) {
                background-color: $sidebar-light-bg;
            }
        }

        .main-header.border-bottom-0 ~ .content-wrapper {
            margin-top: $main-header-height-inner;
        }

        .content-wrapper {
            margin-top: $main-header-height;
        }

        .main-header.text-sm ~ .content-wrapper {
            margin-top: $main-header-height-sm;
        }

        .main-header {
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: $zindex-main-header - 1;
        }
    }

    .layout-navbar-fixed.text-sm & {
        .content-wrapper {
            margin-top: $main-header-height-sm;
        }
    }

    .layout-navbar-not-fixed & {
        .brand-link {
            position: static;
        }

        .sidebar,
        .content-wrapper {
            margin-top: 0;
        }

        .main-header {
            position: static;
        }
    }

    .layout-navbar-not-fixed.layout-fixed & {
        .sidebar {
            margin-top: 0;
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .layout#{$infix}-navbar-fixed.layout-fixed & {
                .control-sidebar {
                    top: $main-header-height;
                }

                .text-sm & .main-header ~ .control-sidebar,
                .main-header.text-sm ~ .control-sidebar {
                    top: $main-header-height-sm;
                }

                .sidebar {
                    margin-top: $main-header-height;
                }

                .text-sm & .brand-link ~ .sidebar,
                .brand-link.text-sm ~ .sidebar {
                    margin-top: $main-header-height-sm;
                }
            }

            .layout#{$infix}-navbar-fixed.layout-fixed.text-sm & {
                .control-sidebar {
                    top: $main-header-height-sm;
                }

                .sidebar {
                    margin-top: $main-header-height-sm;
                }
            }

            .layout#{$infix}-navbar-fixed & {
                .control-sidebar {
                    top: 0;
                }

                a.anchor {
                    display: block;
                    position: relative;
                    top: calc((#{$main-header-height-inner} + #{$main-header-bottom-border-width} + (#{$main-header-link-padding-y} * 2)) / -1);
                }

                &.sidebar-collapse {
                    .brand-link {
                        height: $main-header-height;
                        transition: width $transition-speed $transition-fn;
                        width: $sidebar-mini-width;

                        .text-sm &,
                        &.text-sm {
                            height: $main-header-height-sm;
                        }
                    }

                    .main-sidebar:hover {
                        .brand-link {
                            transition: width $transition-speed $transition-fn;
                            width: $sidebar-width;
                        }
                    }
                }

                .brand-link {
                    overflow: hidden;
                    position: fixed;
                    top: 0;
                    transition: width $transition-speed $transition-fn;
                    width: $sidebar-width;
                    z-index: $zindex-main-header + 1;
                }

                .content-wrapper {
                    margin-top: $main-header-height;
                }

                .text-sm & .main-header ~ .content-wrapper,
                .main-header.text-sm ~ .content-wrapper {
                    margin-top: $main-header-height-sm;
                }

                .main-header {
                    left: 0;
                    position: fixed;
                    right: 0;
                    top: 0;
                    z-index: $zindex-main-sidebar - 1;
                }
            }

            .layout#{$infix}-navbar-fixed.text-sm & {
                .content-wrapper {
                    margin-top: $main-header-height-sm;
                }
            }

            body:not(.layout-fixed).layout#{$infix}-navbar-fixed & {
                // .main-sidebar {
                //   margin-top: calc(#{$main-header-height} / -1);

                //   .sidebar {
                //     margin-top: $main-header-height;
                //   }
                // }
            }

            body:not(.layout-fixed).layout#{$infix}-navbar-fixed.text-sm & {
                .main-sidebar {
                    margin-top: calc(#{$main-header-height-sm} / -1);

                    .sidebar {
                        margin-top: $main-header-height-sm;
                    }
                }
            }

            .layout#{$infix}-navbar-not-fixed & {
                .brand-link {
                    position: static;
                }

                .sidebar,
                .content-wrapper {
                    margin-top: 0;
                }

                .main-header {
                    position: static;
                }
            }

            .layout#{$infix}-navbar-not-fixed.layout-fixed & {
                .sidebar {
                    margin-top: 0;
                }
            }
        }
    }

    .layout-footer-fixed & {
        .control-sidebar {
            bottom: 0;
        }

        .main-footer {
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            z-index: $zindex-main-footer;
        }
    }

    .layout-footer-not-fixed & {
        .main-footer {
            position: static;
        }

        .content-wrapper {
            margin-bottom: 0;
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            .layout#{$infix}-footer-fixed & {
                .control-sidebar {
                    bottom: 0;
                }

                .main-footer {
                    bottom: 0;
                    left: 0;
                    position: fixed;
                    right: 0;
                    z-index: $zindex-main-footer;
                }

                .content-wrapper {
                    padding-bottom: $main-footer-height;
                }
            }

            .layout#{$infix}-footer-not-fixed & {
                .main-footer {
                    position: static;
                }
            }
        }
    }

    .layout-top-nav & {
        margin-left: 0;

        .main-header {
            .brand-image {
                margin-top: -.5rem;
                margin-right: .2rem;
                height: 33px;
            }
        }

        .main-sidebar {
            bottom: inherit;
            height: inherit;
        }

        .content-wrapper,
        .main-header,
        .main-footer {
            margin-left: 0;
        }
    }
}


body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) {
    .content-wrapper,
    .main-footer,
    .main-header {
        &,
        &::before {
            margin-left: 0;
        }
    }
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) {
    .content-wrapper,
    .main-footer,
    .main-header {
        @include media-breakpoint-up(md) {
            @include transition(margin-left $transition-speed $transition-fn);

            margin-left: $sidebar-width;

            .sidebar-collapse & {
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }
}

.sidebar-mini-md {
    .content-wrapper,
    .main-footer,
    .main-header {
        @include media-breakpoint-up(md) {
            @include transition(margin-left $transition-speed $transition-fn);

            margin-left: $sidebar-width;

            .sidebar-collapse & {
                margin-left: $sidebar-mini-width;
            }
        }
        @include media-breakpoint-down(md) {
            margin-left: $sidebar-mini-width;
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }
}

.sidebar-mini-xs {
    .content-wrapper,
    .main-footer,
    .main-header {
        @include media-breakpoint-up(md) {
            @include transition(margin-left $transition-speed $transition-fn);

            margin-left: $sidebar-width;

            .sidebar-collapse & {
                margin-left: $sidebar-mini-width;
            }
        }
        @include media-breakpoint-down(md) {
            margin-left: $sidebar-mini-width;
        }
    }
}

.content-wrapper {
    background-color: $main-bg;

    > .content {
        padding: $content-padding-y $content-padding-x;
    }
}

.main-sidebar {
    &,
    &::before {
        $local-sidebar-transition: margin-left $transition-speed $transition-fn, width $transition-speed $transition-fn;
        @include transition($local-sidebar-transition);
        width: $sidebar-width;
    }

    .sidebar-collapse:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) & {
        &,
        &::before {
            box-shadow: none !important;
        }
    }

    .sidebar-collapse & {
        &,
        &::before {
            margin-left: -$sidebar-width;
        }

        .nav-sidebar.nav-child-indent .nav-treeview {
            padding: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        &,
        &::before {
            box-shadow: none !important;
            margin-left: -$sidebar-width;
        }

        .sidebar-open & {
            &,
            &::before {
                margin-left: 0;
            }
        }
    }
}

body:not(.layout-fixed) {
    .main-sidebar {
        height: inherit;
        min-height: 100%;
        position: absolute;
        top: 0;

        .sidebar {
            overflow-y: auto;
        }
    }
}

.layout-fixed {
    .brand-link {
        width: $sidebar-width;
    }

    .main-sidebar {
        bottom: 0;
        float: none;
        left: 0;
        position: fixed;
        top: 0;
    }

    .control-sidebar {
        bottom: 0;
        float: none;
        position: fixed;
        top: 0;

        .control-sidebar-content {
            height: calc(100vh - #{$main-header-height});
            overflow-y: auto;
            @include scrollbar-width-thin();
            @include scrollbar-color-gray();
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .layout-fixed {
        .main-sidebar {
            height: inherit;
        }
    }
}

.main-footer {
    background-color: $main-footer-bg;
    border-top: $main-footer-border-top;
    color: lighten($gray-700, 25%);
    padding: $main-footer-padding;

    .text-sm &,
    &.text-sm {
        padding: $main-footer-padding-sm;
    }
}

.content-header {
    padding: 15px $content-padding-x;

    .text-sm & {
        padding: 10px $content-padding-x;
    }

    h1 {
        font-size: 1.8rem;
        margin: 0;

        .text-sm & {
            font-size: 1.5rem;
        }
    }

    .breadcrumb {
        background-color: transparent;
        line-height: 1.8rem;
        margin-bottom: 0;
        padding: 0;

        .text-sm & {
            line-height: 1.5rem;
        }
    }
}


.hold-transition {
    .content-wrapper,
    .main-header,
    .main-sidebar,
    .main-sidebar *,
    .control-sidebar,
    .control-sidebar *,
    .main-footer {
        transition: none !important;
        animation-duration: 0s !important;
    }
}

@include dark-mode() {
    background-color: $dark-main-bg;
    color: $white;

    .wrapper {
        .layout-navbar-fixed & {
            @each $name, $color in $theme-colors-alt {
                .sidebar-dark-#{$name} .brand-link:not([class*="navbar"]) {
                    background-color: $sidebar-dark-bg;
                }

                .sidebar-light-#{$name} .brand-link:not([class*="navbar"]) {
                    background-color: $sidebar-light-bg;
                }
            }
        }

        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

                .layout#{$infix}-navbar-fixed & {
                    @each $name, $color in $theme-colors-alt {
                        .sidebar-dark-#{$name} .brand-link:not([class*="navbar"]) {
                            background-color: $sidebar-dark-bg;
                        }

                        .sidebar-light-#{$name} .brand-link:not([class*="navbar"]) {
                            background-color: $sidebar-light-bg;
                        }
                    }
                }
            }
        }
    }

    .breadcrumb-item {
        &.active,
        + .breadcrumb-item::before {
            color: $gray-500;
        }
    }
    .main-footer {
        background-color: $dark;
        border-color: lighten($dark, 10%);
    }
    .content-wrapper {
        background-color: lighten($dark, 7.5%);
        color: $white;

        .content-header {
            color: $white;
        }
    }
}
